window.loaded = false;
var windowWidth = 0;
var headerSlider = undefined;

$(window).on('load', function(){
	window.loaded = true;
});


$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	__createFormInputs();

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});

	
	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});
	
	if( $('.cookie-alert-outer').length > 0 ) {
		setTimeout(function(){
			var height = $('.cookie-alert-outer .content-width').outerHeight();
			$('.cookie-alert-outer').animate({'height':height+'px'}, 600);
		}, 550);

		var alertResize = debounce(function(){
			if(!$('.cookie-alert-outer').hasClass('closed')){
				var height = $('.cookie-alert-outer .content-width').outerHeight();
				$('.cookie-alert-outer').height(height);
			}
		}, 50);

		window.addEventListener('resize', alertResize);

		$('.close-cookie-alert').click(function(){
			$('.cookie-alert-outer').animate({'height':'0px'}, 600);
			$('.cookie-alert-outer').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		
		if (item.length > 0) {
			if ($(this).hasClass('mobile-menu-item')) {
				mobile_menu.changeMenuVisibility();
			}

			$('html, body').animate({scrollTop: item.offset().top}, 400);
		}
	});
	
	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			var resizeFn = function(){
				if (windowWidth != $(window).width()){
					windowWidth = $(window).width();
					if (typeof headerSlider !== 'undefined') {
						headerSlider.destroySlider();
					}

					initSlider();
				}
			}
			
			window.addEventListener('resize', resizeFn);
			initSlider();

			
			if (typeof galleryItems !== 'undefined') {
				$('a.gallery-item').click(function(){
					var index = $(this).data('index');
					if (!index){
						index = 0;
					}
					
					$(this).lightGallery({
						dynamic:true,
						dynamicEl:galleryItems,
						index:index,
						hash:false,
						download: false
					});
				});
			}
		}
	}

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});
});

function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag){
	if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}


function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function __createFormInputs() {
	$('.mdl-js-textfield').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		if (label == undefined) {
			label = input.attr('placeholder');
			input.attr('placeholder', null);
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name');
			input.attr('id', id);
		}
		var classes = 'mdl-textfield';
		th.addClass(classes);
		input.addClass('mdl-textfield__input');

		if (label != undefined) {
			th.addClass('mdl-textfield--floating-label');
			th.append('<label class="mdl-textfield__label" for="' + id + '">' + label + '</label>');
		}

	});

	$('.mdl-checkbox').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		if (label == undefined) {
			label = input.attr('value');
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name') + '_' + ind;
			input.attr('id', id);
		}
		th.attr('for', id);
		var classes = 'mdl-js-checkbox mdl-js-ripple-effect';
		th.addClass(classes);
		input.addClass('mdl-checkbox__input');

		th.append('<span class="mdl-checkbox__label">' + label + '</span>');

	});

	$('.mdl-radio').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		var error = th.data('error');
		if (label == undefined) {
			label = input.attr('value');
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name') + '_' + ind;
			input.attr('id', id);
		}
		th.attr('for', id);
		var classes = 'mdl-js-radio mdl-js-ripple-effect';
		th.addClass(classes);
		input.addClass('mdl-radio__button');

		th.append('<span class="mdl-radio__label">' + label + '</span>');

		if (error) {
			th.append('<span class="mdl-textfield__error">' + error + '</span>');
		}

	});

	$('.custom-select:not(".with-button")').each(function(ind, el) {
		var th = $(el);
		var options = th.children('select');
		var id = options.attr('name');
		var label = th.children('.mdl-js-textfield');
		var defaultValue = '';

		var ul = th.children('ul');
		options.children().each(function(ind2, el2) {
			var th2 = $(el2);
			if (!ind2) defaultValue = th2.text();
			ul.append('<li class="mdl-menu__item" data-value="' + th2.attr('value') + '">' + th2.text() + '</li>');
		});
		ul.attr('for', id).addClass('mdl-menu--bottom-left mdl-js-menu mdl-js-ripple-effect');

		label.click(function(e) {
			e.preventDefault();
			e.stopPropagation();
			label.addClass('is-dirty');
			th.toggleClass('active');
			th.find('.mdl-menu__container').toggleClass('is-visible');
		});

		th.find('li').click(function() {
			var sel = $(this).text();
			label.removeClass('is-invalid').children('input').val(sel);
			options.val(sel);
			if (!options.val()) label.removeClass('is-dirty');
		});

		label.find('input').val(defaultValue);

	});

	$('.custom-select.with-button').each(function(ind, el) {
		var th = $(el);
		var options = th.children('select');
		var id = options.attr('name');
		var label = th.data('label');
		var url = th.data('url');

		var ul = th.children('ul');
		options.children().each(function(ind2, el2) {
			var th2 = $(el2);
			ul.append('<li class="mdl-menu__item" data-value="' + th2.attr('value') + '">' + th2.text() + '</li>');
		});
		ul.attr('for', id).addClass('mdl-menu--bottom-left mdl-js-menu mdl-js-ripple-effect');
		th.append('<a class="button orange-button" href="javascript:void(0)" id="' + id + '">' + label + '<span class="sprite mainSprite-whiteSelectArrow"></span></a>');

		th.find('.button').click(function(e) {
			e.preventDefault();
			e.stopPropagation();
			$(this).toggleClass('clicked');
		});

		th.find('li').click(function() {
			var sel = $(this).data('value');
			options.val(sel);

			if (url != undefined) {
				window.location = url + sel;
			}
		});

	});

	refreshInputs();

	
}

function refreshInputs() {
	componentHandler.upgradeAllRegistered();
}

function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}

function googleMapsLoaded()
{
	loadJs(base_url + 'assets/js/infoBox.js', function() {
		if (window.loaded) {
			$(document).trigger('pwcms.googlemapsloaded');
		} else {
			$(window).on('load', function(){
				$(document).trigger('pwcms.googlemapsloaded');
			});
		}
	});
}

function initSlider()
{
	headerSlider = $('#headerSlider').bxSlider({
		auto:true,
		pause:10000,
		infiniteLoop: true,
		touchEnabled: true,
		preventDefaultSwipeX: true,
		preventDefaultSwipeY: false,
	});
}

function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function getMapStyle()
{
	return [
		{
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#e9e9e9"
				},
				{
					"lightness": 17
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f5f5f5"
				},
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 17
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 29
				},
				{
					"weight": 0.2
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 18
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 16
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f5f5f5"
				},
				{
					"lightness": 21
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#dedede"
				},
				{
					"lightness": 21
				}
			]
		},
		{
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#ffffff"
				},
				{
					"lightness": 16
				}
			]
		},
		{
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"saturation": 36
				},
				{
					"color": "#333333"
				},
				{
					"lightness": 40
				}
			]
		},
		{
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f2f2f2"
				},
				{
					"lightness": 19
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#fefefe"
				},
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#fefefe"
				},
				{
					"lightness": 17
				},
				{
					"weight": 1.2
				}
			]
		}
	];
}
