(function(wnd){
    function ReferenceFilter(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }
            
            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    ReferenceFilter.prototype = {
        container:undefined,
        buttons:undefined,
        allButton: undefined,
        listContainer: undefined,
        galleries:{},
        init: function() {
            this.setElements();
            this.addDOMEvents();

            this.loadMediaContent();
        },
        setElements: function() {
            this.buttons = this.container.find('[data-purpose="filter-item"]');
            this.allButton = this.container.find('[data-purpose="filter-item"][data-tag-id="all"]');
            this.listContainer = this.container.find('[data-purpose="reference-list"]');
        },
        addDOMEvents: function() {
            var _self = this;
            if (this.buttons) {
                this.buttons.click(function(e){
                    if (!$(this).hasClass('clicked')){
                        var btn = $(this);
                        var id = $(this).data('tag-id');
                        var all = isNaN(id);

                        
                        if ($(this).hasClass('selected')) {
                            $(this).removeClass('selected');
                            if (_self.container.find('.selected[data-purpose="filter-item"]').length == 0) {
                                _self.allButton.addClass('selected');
                            }
                        } else {
                            _self.buttons.removeClass('selected');
                            $(this).addClass('selected');
                        }
                        
                        setTimeout(function(){
                            btn.removeClass('clicked');
                        }, 500);

                        _self.sendForm();
                    }
                });
            }

            this.addReferenceBoxEvents()
        },
        addReferenceBoxEvents: function() {
            var _self = this;

            this.listContainer.find('[data-purpose="reference-box"]').click(function(){
                var id = $(this).data('reference-id');
                if (typeof _self.galleries[id] !== 'undefined') {
                    $(this).lightGallery({
                        dynamic:true,
                        dynamicEl: _self.galleries[id],
                        hash:false,
                        index:0,
                        download:false,
                    });
                }
            });
        },
        setParams: function(params) {
            // console.log(params)
        },
        sendForm: function() {
            var _self = this;
            var tags = this.getSelectedTags();
            
            $.ajax({
                url:ajax_controller+'reference_filter',
                dataType:'json',
                type:'post',
                data: {
                    tags: tags
                },
                success: function (response) {
                    _self.listContainer.html(response.html);
                    _self.addReferenceBoxEvents();
                    _self.loadMediaContent();
                }
            });
        },
        getSelectedTags: function() {
            var tags = [];
            this.container.find('.selected[data-purpose="filter-item"]').each(function(){
                var id = $(this).data('tag-id');
                if (!isNaN(id)) {
                    tags.push(id);
                }
            });
            return tags;
        },
        loadMediaContent: function()
        {
            var _self = this;
            var unloadedItems = [];
            
            this.listContainer.find('[data-purpose="reference-box"]').each(function(){
                var id = $(this).data('reference-id');
                if (typeof _self.galleries[id] == 'undefined' && unloadedItems.indexOf(id) < 0) {
                    unloadedItems.push(id);
                }
            });

            if (unloadedItems.length > 0) {
                $.ajax({
                    url:ajax_controller+'reference_medias',
                    dataType:'json',
                    type:'post',
                    data: {
                        ids: unloadedItems
                    },
                    success: function(response) {
                        _self.galleries = $.extend({}, _self.galleries, response.galleries);
                    }
                });
            }
        }
    };

    wnd.ReferenceFilter = ReferenceFilter;
})(window);
